import '@amzn/awsui-global-styles/polaris.css';
import { Switch } from 'react-router-dom';
import { GlobalConstants } from '../constants/UXConstants';
import CommonHeader from './CommonHeader';
import LocalRoutes from './navigation/Routes';

export default function App() {
  return (
    <div className="awsui-visual-refresh">
      <div id={GlobalConstants.TOP_HEADER_ID} style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
        <CommonHeader />
      </div>
      <Switch>
        <LocalRoutes />
      </Switch>
    </div>
  );
}
