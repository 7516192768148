
export abstract class GlobalConstants {
  public static TOP_HEADER_ID = 'top_header';
  public static API_URL_CACHE_KEY: string = 'apiUrl';
  public static OPERATORS = ['<', '>', '<=', '>=', '=', '!=', '<>'];
  public static TABLE_CHUNK_SIZE = 15;
}

export abstract class StringConstants {
  public static DATA_TEST_ID_PREFIX = 'test_id';
  public static DROP_DOWN_CLEAR_VALUE = '-- Clear --';
}
export abstract class ComponentRules {
  public static HELPER_INFO = 'helperInfo';
  public static REQUIRED = 'required';
}

export enum ValueType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
  COMPARATIVE_DOUBLE = 'COMPARATIVE_DOUBLE',
  COMPARATIVE_INTEGER = 'COMPARATIVE_INTEGER',
  COMPARATIVE_STRING = 'COMPARATIVE_STRING'
}


export enum NumericalConditions {
  "GREATER_THAN" = ">",
  "GREATER_THAN_EQUALS" = ">=",
  "LESS_THAN" = "<",
  "LESS_THAN_EQUALS" = "<=",
  "EQUALS" = "=",
  "NOT_EQUALS" = "!="
}

export enum StringConditions {
  "CONTAINS" = "contains",
  "STARTS_WITH" = "starts with",
  "ENDS_WITH" = "ends with",
  "EQUALS" = "equals",
  "NOT_EQUALS" = "not equals"
}