let cache = {};

export function getAppSettings(key: string) {
  return cache[key];
}

export function initializeAppSettings(settings?: {[key: string]: any}) {
  cache = { ...settings, apiUrl: `api.${initApiUriHostName()}` };
  Object.freeze(cache);
}

/**
 * Function is used to extract hostname to be used for Api Calls. A small workaround for live server cases.
 * If you are running in local live server such as https://bisdipan.amazon.com:4443 then it will return the dev-host-name
 * Crafted to cater for local development.
 * In non-local cases it will return the browser host-name directly
 * @returns
 */
function initApiUriHostName(): string {
  const local_server_hostname_regex = /([a-z])\w+.amazon.com\:([0-9]{4})/g;
  const browser_href = window.location.href;
  if (browser_href.match(local_server_hostname_regex)) {
    return 'devo.alacarte-exp.ab.amazon.dev';
  }
  const UX_HOST_NAME_PREFIX = 'ux.';
  const browser_host_name = window.location.hostname;
  if (browser_host_name.startsWith(UX_HOST_NAME_PREFIX)) {
    return browser_host_name.replace(UX_HOST_NAME_PREFIX, '');
  }
  console.log(
    `The website URI doesn\'t contain the UX_URI_Prefix [${UX_HOST_NAME_PREFIX}], deafulting to -> ${browser_host_name}`
  );
  return browser_host_name;
}
