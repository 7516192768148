import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import { GlobalConstants } from "../../constants/UXConstants";
import WelcomePage from "../home/WelcomeMainPage";

export default function WelcomeApp(props) {  

  return (
    <AppLayout
      content={<WelcomePage />}
      contentType="default"
      navigationHide={true}
      toolsHide={true}
      disableContentPaddings={true}
      headerSelector={'#' + GlobalConstants.TOP_HEADER_ID}
    />
  );
}
