import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import axiosSlice from './global/axiosSlice';
import cacheSlice from './global/cacheSlice';
import pluginProviderSlice from './global/pluginProviderSlice';
import formDataSlice from './global/formDataSlice';
import routingSlice from './routing/routingSlice';

const rootPersistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['pageRouting'],
};

const dataEntityPersistConfig = {
  key: 'service',
  storage: sessionStorage,
  blacklist: ['plugin_function', 'data_props'],
};

const rootReducer = combineReducers({
  pluginProvider: persistReducer(dataEntityPersistConfig, pluginProviderSlice),
  pageRouting: routingSlice,
  formData: formDataSlice,
  axiosCall: axiosSlice,
  cache: cacheSlice
});

const rootPersistReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: rootPersistReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
