import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FormData {
  formData: { [key: string]: any };
}

const initialState = {
  formData: {},
};

const formDataSlice = createSlice({
  name: 'formData',
  initialState,
  reducers: {
    addFormData: (state, action: PayloadAction<{ [key: string]: any }>) => {
      Object.assign(state.formData, action.payload);
    },
    initializeFormInput: (state, action: PayloadAction<object>) => {
      state.formData = action.payload;
    },
    deleteFormData: (state, action: PayloadAction<string>) => {
      delete state.formData[action.payload];
    },
    resetFormData: (state) => {
      state = JSON.parse(JSON.stringify(initialState));
    },
  },
});

export const { addFormData, initializeFormInput, deleteFormData, resetFormData } = formDataSlice.actions;

export default formDataSlice.reducer;
