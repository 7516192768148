import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CacheState {
  cachedResponse: { [key: string]: any };
}

const initialState: CacheState = {
  cachedResponse: {},
};

const cacheSlice = createSlice({
  name: 'CacheSlice',
  initialState,
  reducers: {
    initializeCacheWithTestDataGroup: (state, action: PayloadAction<string>) => {
      state.cachedResponse = { ...state.cachedResponse, [action.payload]: {} };
    },
    updateCachedResponse: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.cachedResponse = action.payload;
    },
  },
});

export const { initializeCacheWithTestDataGroup, updateCachedResponse } = cacheSlice.actions;

export default cacheSlice.reducer;
