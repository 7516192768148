import { Dispatch, AnyAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getAppSettings } from '../config/AppSettings';
import { GlobalConstants } from '../constants/UXConstants';
import { ResponseDTO } from '../models/FormResponse';
import { toggleWaitingForResponseFlag } from '../redux/global/axiosSlice';

export const makeAsyncPostRequestToService = async (
  path: string,
  postData: object,
  requestHeaders?: object,
  dispatch?: Dispatch<AnyAction>
) => {
  dispatch?.(toggleWaitingForResponseFlag());
  let responseObject: ResponseDTO = { response: undefined, error: undefined };
  const axiosUrl = getBaseApiGwUrl() + '/' + path.toString();
  await axios
    .post(axiosUrl, postData, requestHeaders)
    .then((res) => {
      responseObject.response = res;
    })
    .catch((err) => {
      responseObject.error = err.response || err.data;
    })
    .finally(() => {
      dispatch?.(toggleWaitingForResponseFlag());
    });
  return responseObject;
};

const getBaseApiGwUrl = () => {
  const apiBasePath = `https://${getAppSettings(GlobalConstants.API_URL_CACHE_KEY)}/ux`;
  return apiBasePath;
};
