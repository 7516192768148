import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AxiosState {
  isWaitingForResponse: boolean;
  responseStatus: number;
  responseData: any;
  responseError: any;
}

const initialState: AxiosState = {
  isWaitingForResponse: false,
  responseStatus: 0,
  responseData: null,
  responseError: null,
};

const axiosSlice = createSlice({
  name: 'serviceResponse',
  initialState,
  reducers: {
    updateResponseStatus: (state, action: PayloadAction<number>) => {
      state.responseStatus = action.payload;
    },
    updateResponseData: (state, action: PayloadAction<any>) => {
      state.responseData = action.payload;
    },
    updateResponseError: (state, action: PayloadAction<any>) => {
      state.responseError = action.payload;
    },
    toggleWaitingForResponseFlag: (state) => {
      state.isWaitingForResponse = !state.isWaitingForResponse;
    },
    resetResponseObject: (state) => {
      state.responseData = null;
      state.responseError = null;
      state.responseStatus = 0;
    },
  },
});

export const {
  updateResponseStatus,
  updateResponseData,
  updateResponseError,
  toggleWaitingForResponseFlag,
  resetResponseObject,
} = axiosSlice.actions;

export default axiosSlice.reducer;
