import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PageRoutingOptions {
    pageName: string;
    href: string;
}

const initialState: PageRoutingOptions = {
    pageName: '',
    href: '',
}

const routingSlice = createSlice({
    name: 'routingSlice',
    initialState,
    reducers: {
        updateRoutingPageData: (state, action: PayloadAction<PageRoutingOptions>) => {
            Object.assign(state, action.payload)
        }
    }
});

export const { updateRoutingPageData } = routingSlice.actions;

export default routingSlice.reducer