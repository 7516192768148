export const amazonEmberFontStyle = {
  fontFamily: 'Amazon Ember',
};

export const darkBlueFontColorStyle = {
  color: 'darkblue',
};

export const inputFormLabelStyle = {
  ...amazonEmberFontStyle,
  ...darkBlueFontColorStyle,
  fontSize: '1.1em',
  paddingBottom: '1em',
};

export const helperTextStyle = {
  ...amazonEmberFontStyle,
  fontSize: '0.65em',
};

export const muiSelectTextStyle = {
  ...amazonEmberFontStyle,
  fontSize: '1em',
};

export const mainPageCardHeaderStyle = {
  ...amazonEmberFontStyle,
  fontSize: '1.25vmax',
  paddingTop: '1.2rem',
  color: '#232f3e',
  fontWeight: 550,
  textAlign: 'center'
}

export const lightBlueLinkStyle = {
  textDecoration: 'none',
  color: '#1E88E5'
}