import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FrontendConfig } from "../../models/FrontendConfig";

export interface DataProviderState {
  plugin_provider: string;
  plugin_function: string | undefined;
  data_props: { [key: string]: { [key: string]: FrontendConfig } };
  error: {[key: string]: any}
}

const initialState: DataProviderState = {
  plugin_provider: '',
  plugin_function: undefined,
  data_props: {},
  error: {}
};

const pluginProviderSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    updatePluginProvider: (state, action: PayloadAction<string>) => {
      state.plugin_provider = action.payload;
    },
    updatePluginFunction: (state, action: PayloadAction<string | undefined>) => {
      state.plugin_function = action.payload;
    },
    updateDataProps: (state, action: PayloadAction<{ [key: string]: { [key: string]: FrontendConfig } }>) => {
      state.data_props = action.payload;
    },
    updateConfigError: (state, action: PayloadAction<{ [key: string]: any}>) => {
      state.error = action.payload;
    },
    resetConfigError: (state) => {
      state.error = {}
    }
  },
});

export const { updatePluginProvider, updatePluginFunction, updateDataProps, updateConfigError, resetConfigError } = pluginProviderSlice.actions;

export default pluginProviderSlice.reducer;
