import { Typography } from '@mui/material';
import { amazonEmberFontStyle } from '../../constants/ReactStyles';
import './styles.scss';

interface HeaderItemProps {
  text: string;
  href?: string;
  newtab?: boolean;
}

export default function HeaderItem(props: HeaderItemProps) {
  return (
    <a href={props.href} target={props.newtab ? '_blank' : '_self'} style={{ textDecoration: 'none' }}>
      <div className="header-item">
        <Typography component="div" sx={{ ...amazonEmberFontStyle, fontSize: '1.5em' }}>
          {props.text}{' '}
        </Typography>
      </div>
    </a>
  );
}
