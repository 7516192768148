import { AppLayout } from "@amzn/awsui-components-react";
import { GlobalConstants } from "../../constants/UXConstants";
import PluginsMainPage from "../home/PluginsMainPage";

export default function PluginsApp() {
    return (
      <AppLayout
        content={<PluginsMainPage />}
        contentType="default"
        navigationHide={true}
        toolsHide={true}
        disableContentPaddings={true}
        headerSelector={'#' + GlobalConstants.TOP_HEADER_ID}
      />
    );
}
