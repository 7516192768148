import Box from '@amzn/awsui-components-react/polaris/box';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import LightHouse from '../../assets/LightHouse.png';
import { lightBlueLinkStyle, mainPageCardHeaderStyle } from '../../constants/ReactStyles';
import './styles.scss';

export default function WelcomePage() {
  return (
    <div id="home-page-main-container">
      <Grid container>
        <Grid item xs={5}>
          <Box>
            <img src={LightHouse} alt="AlaCarte" id="light-house" />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <div id="alc-title">
            <Typography
              variant="h1"
              style={{
                fontFamily: 'Amazon Ember',
                color: '#232f3e',
                fontSize: '4.5vw',
                fontWeight: '575',
              }}
            >
              AlaCarte{' '}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} justifyContent="center" alignItems="center" display="flex" style={{ padding: '2vmin' }}>
          <Card variant="elevation" id="feature-cards">
            <Typography variant="h3" sx={mainPageCardHeaderStyle}>
              AlaCarte in a Nutshell
            </Typography>
            <CardContent>
              AlaCarte is a platform, where you can easily plugin your data query/creation Lambda for your customers to
              consume from UI (Frontend) and API (Backend). UI is a Single Page Application (SPA) hosted on{' '}
              <a href="https://aws.amazon.com/cloudfront/" target="_blank" rel="noreferrer" style={lightBlueLinkStyle}>
                CloudFront
              </a>{' '}
              and API is an integration of{' '}
              <a
                href="https://docs.aws.amazon.com/apigateway/latest/developerguide/set-up-lambda-proxy-integrations.html"
                target="_blank"
                rel="noreferrer"
                style={lightBlueLinkStyle}
              >
                AWS API Gateway backed by Lambda Function
              </a>
              .
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} justifyContent="center" alignItems="center" display="flex" style={{ padding: '2vmin' }}>
          <Card variant="elevation" id="feature-cards">
            <Typography variant="h3" sx={mainPageCardHeaderStyle}>
              Work with Plugins{' '}
            </Typography>
            <CardContent>
              With AlaCarte you can define your own plugins. It can be a Lambda written with any language your prefer,
              hosted within AWS account that you control. We also have out of the box query plugins, if you have data
              hosted in S3 bucket or DynamoDB.
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} justifyContent="center" alignItems="center" display="flex" style={{ padding: '2vmin' }}>
          <Card variant="elevation" id="feature-cards">
            <Typography variant="h3" sx={mainPageCardHeaderStyle}>
              Out of the Box Infrastructure
            </Typography>
            <CardContent>
              Want to integrate but don't have any plugins ready, don't worry we will take care. We provide a custom{' '}
              <a
                href="https://code.amazon.com/packages/AlaCarteCDKConstruct/trees/mainline"
                target="_blank"
                rel="noreferrer"
                style={lightBlueLinkStyle}
              >
                AlaCarte CDK Construct
              </a>{' '}
              to help you deploy your Lambda, with the ability to have VPC and Superstar configured. No need to stress
              about CDK code. We also provide you a{' '}
              <a
                href="https://code.amazon.com/packages/AlaCarteConfigurations/blobs/mainline/--/alc-onboard-wizard.sh"
                target="_blank"
                rel="noreferrer"
                style={lightBlueLinkStyle}
              >
                custom script
              </a>{' '}
              to setup Version Set, Pipeline, Bindle and code packages.
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} justifyContent="center" alignItems="center" display="flex" style={{ padding: '2vmin' }}>
          <Card variant="elevation" id="feature-cards">
            <Typography variant="h3" sx={mainPageCardHeaderStyle}>
              Incremental Development
            </Typography>
            <CardContent>
              You can use our existing UI Templates to host your UI. In case those are not appealing to you. You want to
              build your own UI, then come along and contribute to our UI templates or build a custom page on
              React-Redux. The templates you build will be available for use to everyone on AlaCarte, a lasting
              impression of your contribution
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
