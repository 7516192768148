export const routeConfig = (): { [key: string]: any } => {
    return {
    "absxasindata": {
        "type": "link",
        "label": "ABSX ASIN Data",
        "href": "#/absxasindata",
        "route": "TabularQueryFormApp"
    },
    "asindata": {
        "type": "link",
        "label": "Asin Data Service",
        "href": "#/asindata",
        "route": "TabularQueryFormApp"
    },
    "tas": {
        "type": "link",
        "label": "TAS",
        "href": "#/tas",
        "route": "TabularQueryFormApp"
    },
    "tdf": {
        "type": "link",
        "label": "TDF",
        "href": "#/tdf",
        "route": "TwoContainerApp"
    }
}
}