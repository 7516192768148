// Promise polyfill
import axios from 'axios';
import 'core-js/features/promise';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { getMidwayJwtToken } from './auth/MidwayJwtToken';
import App from './components/App';
import { initializeAppSettings } from './config/AppSettings';
import { persistor, store } from './redux/store';

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken();
  config.headers.Authorization = `Bearer ${jwtToken}`;
  return config;
});

(async () => {
  // Make sure Midway is present before rendering
  await getMidwayJwtToken();

  // Initialize application settings
  //[Removing the settings file load which loaded the ApiUrl, as ApiUrl is now formed based on window.location]
  //In future if any static settings need to be initialized for app, uncomment this and create a file as 'localSettings/settings.json'
  //const appSettings = (await axios("/settings.json")).data;

  initializeAppSettings();

  render(
    <HashRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </HashRouter>,
    document.querySelector('#app')
  );
})();
